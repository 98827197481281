export const UserRole = {
  Admin: 0,
  Editor: 1,
  Visitor: 2,
  DeviceOwner: 3,
};
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-hidden';

// interval at which we push updates to the server while dragging, in ms
// WARNING: if using REST instead of websocket, this probably has to be a fair bit higher
export const pushInterval = 150;

// interval at which we're fetching data from the server to sync the slider, in ms
export const fetchInterval = 250;

// interval between successive cascaded messages when we send a high-priority update
export const cascadeInterval = 500;

// suppress data that comes in from the server within this many milliseconds of
// our last update to prevent potentially stale data from overwriting our changes
// (this should be somewhat less than the fetch interval)
export const suppressInterval = 200;

// interval for websocket pings to keep the connection alive
export const pingInterval = 10000;

// maximum priority level for updates (if lower will clamp priorities to that)
export const maxPriority = 2;

// base URL of the backend REST API
export const backendBaseUrl =
  'https://x8ew45vqfh.execute-api.us-west-2.amazonaws.com/api';

// base URL of the WebSocket API
export const wssBaseUrl =
  'wss://l7e44g9t7d.execute-api.us-west-2.amazonaws.com/api/';

// whether to use the websocket connection for updates
export const useWebsocket = true;

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const currentUser = null;
// {
//   id: 1,
//   title: 'Sarah Kortney',
//   img: '/assets/img/profiles/l-1.jpg',
//   date: 'Last seen today 15:24',
//   role: UserRole.Visitor,
// };

export const adminRoot = '/shared';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
